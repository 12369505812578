// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-credits-jsx": () => import("./../../../src/pages/credits.jsx" /* webpackChunkName: "component---src-pages-credits-jsx" */),
  "component---src-pages-fieldguides-jsx": () => import("./../../../src/pages/fieldguides.jsx" /* webpackChunkName: "component---src-pages-fieldguides-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-introduction-jsx": () => import("./../../../src/pages/introduction.jsx" /* webpackChunkName: "component---src-pages-introduction-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-stories-jsx": () => import("./../../../src/pages/stories.jsx" /* webpackChunkName: "component---src-pages-stories-jsx" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

